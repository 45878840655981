import '../styles/Navbar.css'
import Logo from '../assets/ChameleonLogo.png'

const Navbar = () => {
    return (
      <header>
        <nav className="navbar">
          <div className="logo">
            <img src= {Logo} alt="Logo" />
          </div>
          <div className="title">
              <h1>Chameleon</h1>
              <p className="mantra">Where Learning Adapts to You</p>
          </div>
        </nav>
      </header>
    );
  };

export default Navbar