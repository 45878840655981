import { useNavigate } from 'react-router-dom';
import '../styles/Student.css'

const Student = ({ name, imageSrc }) => {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate(`/material/${name}`)
    }

    return (
      <div className="student" onClick={handleClick}>
        <img src={imageSrc} alt={name} className="student-image" />
        <h3 className="student-name">{name}</h3>
      </div>
    );
  };

  export default Student