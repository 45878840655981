import {BrowserRouter, Routes, Route, useLocation} from 'react-router-dom'

// Pages & Components
import Home from './pages/HomePage'
import MaterialPage from './pages/MaterialPage'
import Navbar from './components/Navbar'
import Footer from './components/footer'

function App() {
  const location = useLocation();


  return (
    <div className="App">
      <div className="container">
      {location.pathname === '/' && <Navbar />} 
        <div className='pages'>
          <Routes>
            <Route 
              path="/"
              element={<Home />}
            />
            <Route path="/material/:studentName" element={<MaterialPage />} />
          </Routes>
        </div>
        <Footer/>
        </div>
    </div>
  );
}

export default App;
