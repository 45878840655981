import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../assets/ChameleonLogo.png';
import '../styles/MaterialNavbar.css';

const MaterialNavbar = ({ student, otherStudents }) => {
    const navigate = useNavigate();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const handleSignOut = () => {
        navigate('/');
    };

    const handleLogoClick = () => {
        navigate('/');
    };

    const handleStudentSwitch = (studentName) => {
        navigate(`/material/${studentName}`); 
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <nav className="material-navbar">
            <div className="logo-container" onClick={handleLogoClick}>
                <img src={Logo} alt="Logo" className="logo" />
                <div className="site-info">
                    <h1 className="site-name">Chameleon</h1>
                    <p className="mantra">Where Learning adapts to you</p>
                </div>
            </div>
            <div className="profile-container">
                {student && (
                    <div className="profile-dropdown">
                        <img
                            src={student.imageSrc} 
                            alt="Profile"
                            className="profile-icon"
                            onClick={toggleDropdown}
                        />
                        <span className="arrow" onClick={toggleDropdown}>
                            {isDropdownOpen ? ' ▲' : ' ▼'}
                        </span> 
                        {isDropdownOpen && (
                            <div className="dropdown-content">
                                <button onClick={handleSignOut}>Sign Out</button>
                                <h3>Switch Student:</h3>
                                {otherStudents.map((otherStudent) => (
                                    <button key={otherStudent.name} onClick={() => handleStudentSwitch(otherStudent.name)}>
                                        <img src={otherStudent.imageSrc} alt={otherStudent.name} className="student-icon" />
                                        {otherStudent.name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </nav>
    );
};

export default MaterialNavbar;
