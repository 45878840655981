import '../styles/HomePage.css'
import Student from '../components/Student'
import GirlLogo1 from '../assets/Girl1.png'
import BoyLogo from '../assets/Boy1.png'
import GirlLogo2 from '../assets/Girl2.jpeg'

const Home = () => {
    return (
        <div className="homepage">
            <h1>Hello, Future Stars! Let’s Start Our Learning Adventure!</h1>
            <div className="students-container">
                <Student name="Amina" imageSrc={GirlLogo1} />
                <Student name="Omar" imageSrc={BoyLogo}/>
                <Student name="Layla" imageSrc={GirlLogo2} />
            </div>
        </div>
    );
}

export default Home