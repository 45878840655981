import React, { useState } from 'react';
import '../styles/Quiz.css';

const Quiz = ({ quizData, images, onAnswerSelect }) => {
    const { personaContent, questionId } = quizData;
    const { questionText, answerChoices, correctAnswer } = personaContent;

    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [isSubmitted, setIsSubmitted] = useState(false); // Tracks if the student has submitted their answer
    const [feedbackMessage, setFeedbackMessage] = useState('');

    console.log("Images passed to Question component:", images);

    const handleAnswerSelect = (choice) => {
        if (!isSubmitted) { 
            setSelectedAnswer(choice);
        }
    };

    const handleSubmit = () => {
        setIsSubmitted(true);
        const isCorrect = selectedAnswer === correctAnswer;

        onAnswerSelect(questionId, isCorrect);

        // Set feedback message
        if (isCorrect) {
            setFeedbackMessage("Correct!");
        } else {
            setFeedbackMessage(`Incorrect. The correct answer is: ${correctAnswer}`);
        }
    };

    return (
        <div className="quiz">
            {/* Render images if they exist */}
            <p>{questionText}</p>
            {images && images.length > 0 && (
                <div className="quiz-images">
                    {images.map((image, index) => (
                        <img 
                            key={index} 
                            src={image} 
                            alt={`Question image ${index + 1}`} 
                            className="quiz-image"
                        />
                    ))}
                </div>
            )}
            <div className="quiz-answer-choices">
                {answerChoices.map((choice, index) => (
                    <button
                        key={index}
                        className={`quiz-answer-choice ${selectedAnswer === choice ? 'selected' : ''}`}
                        onClick={() => handleAnswerSelect(choice)}
                        disabled={isSubmitted}  // Disable choices after submission
                    >
                        {choice}
                    </button>
                ))}
            </div>
            {!isSubmitted && (
                    <div className="quiz-submit-container">
                        <button onClick={handleSubmit} className="quiz-submit-button">
                            Submit Answer
                        </button>
                    </div>          
            )}
            {isSubmitted && (
                <p className={`quiz-feedback ${feedbackMessage.includes("Correct") ? 'correct' : 'incorrect'}`}>
                    {feedbackMessage}
                </p>
            )}
        </div>
    );
};

export default Quiz;
