// Footer.js
import React from 'react';
import '../styles/footer.css'; // Assuming you’ll add custom styling here
import { FaHeart } from 'react-icons/fa'; // Using Font Awesome for the heart icon

const Footer = () => {
    return (
        <footer className="footer">
            <p className="footer-text">
                Made with <FaHeart className="heart-icon" /> by ITQAN for Smart Solutions @ 2024
            </p>
        </footer>
    );
};

export default Footer;
