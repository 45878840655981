import React, { useState } from 'react';
import '../styles/Question.css';

const Question = ({ questionData, onAnswerSelect, images }) => {
    const { personaContent, questionId } = questionData;
    const { questionText, answerChoices, correctAnswer } = personaContent;

    const [selectedAnswer, setSelectedAnswer] = useState('');
    const [showFeedback, setShowFeedback] = useState(false);
    const [feedbackMessage, setFeedbackMessage] = useState('');

    console.log("Images passed to Question component:", images);

    const handleAnswerSelect = (choice) => {
        setSelectedAnswer(choice);
        setShowFeedback(true);
        const isCorrect = choice === correctAnswer;

        // Call the parent's onAnswerSelect to pass the result back
        onAnswerSelect(questionId, isCorrect);
        
        // Set feedback message
        setFeedbackMessage(isCorrect ? "Correct!" : "Incorrect. Try again.");
    };

    return (
        <div className="question-container">
            {/* Render images if they exist */}

            <p>{questionText}</p>
            {images && images.length > 0 && (
                <div className="question-images">
                    {images.map((image, index) => (
                        <img 
                            key={index} 
                            src={image} 
                            alt={`Question image ${index + 1}`} 
                            className="question-image"
                        />
                    ))}
                </div>
            )}
            <div className="answer-choices">
                {answerChoices.map((choice, index) => (
                    <button
                        key={index}
                        className={`answer-choice ${selectedAnswer === choice ? 'selected' : ''}`}
                        onClick={() => handleAnswerSelect(choice)}
                    >
                        {choice}
                    </button>
                ))}
            </div>
            {showFeedback && (
                <p className={`feedback ${feedbackMessage.includes("Correct") ? 'correct' : 'incorrect'}`}>
                    {feedbackMessage}
                </p>
            )}
        </div>
    );
};

export default Question;
