import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MaterialNavbar from '../components/MaterialNavbar';
import axios from 'axios';
import '../styles/MaterialPage.css';
import Question from '../components/Question'
import Quiz from '../components/Quiz'

const MaterialPage = () => {
    const { studentName } = useParams();
    const [student, setStudent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [lessons, setLessons] = useState([]);
    const [learningStyle, setLearningStyle] = useState('');
    const [lessonContent, setLessonContent] = useState(null);
    const [expandedLessonIndex, setExpandedLessonIndex] = useState(1);
    const [selectedContentType, setSelectedContentType] = useState('explanation');
    const [otherStudents, setOtherStudents] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const [feedback, setFeedback] = useState({}); 

    useEffect(() => {
        const fetchStudentData = async () => {
            try {
                const response = await axios.get(`/students/student/${studentName}`);
                setStudent(response.data);
                setLearningStyle(response.data.learningStyle);
            } catch (error) {
                console.error('Error fetching student data:', error);
            } finally {
                setLoading(false);
            }
        };
        fetchStudentData();
    }, [studentName]);

    useEffect(() => {
        const fetchOtherStudents = async () => {
            try {
                const response = await axios.get('/students/allStudents'); 
                setOtherStudents(response.data.filter(s => s.name !== studentName));
            } catch (error) {
                console.error("Error fetching students:", error);
            }
        };
        fetchOtherStudents();
    }, [studentName]);

    useEffect(() => {
        const fetchLessons = async () => {
            try {
                const response = await axios.get('/lessons/allLessons'); 
                setLessons(response.data);
            } catch (error) {
                console.error("Error fetching lessons:", error);
            }
        };
        fetchLessons();
    }, []);

    useEffect(() => {
        if (lessons.length > 0 && learningStyle) {
            fetchLessonContent(lessons[0].lessonId, 'explanation');
        }
    }, [lessons, learningStyle]);

    useEffect(() => {
        if (studentName && lessons.length > 0) {
            setSelectedContentType('explanation');
            fetchLessonContent(lessons[0].lessonId, 'explanation');
        }
    }, [studentName, lessons]);

    const handleToggle = (index, lessonId) => {
        setExpandedLessonIndex(expandedLessonIndex === index ? null : index);      
    };
    

    const fetchLessonContent = async (lessonId, contentType) => {
        try {
            const endpointMap = {
                explanation: `/lessons/lesson/${lessonId}/explanation/${learningStyle}`,
                exercise: `/lessons/lesson/${lessonId}/exercise/${learningStyle}`,
                quiz: `/lessons/lesson/${lessonId}/quiz/${learningStyle}`
            };

            const response = await axios.get(endpointMap[contentType]);

            if (contentType === 'exercise' || contentType === 'quiz') {
                setLessonContent(response.data);
            } else {
                setLessonContent(response.data.personaContent);
            }
        } catch (error) {
            console.error(`Error fetching ${contentType} content:`, error);
        }
    };

    const handleContentTypeSelect = (lessonId, contentType) => {
        setSelectedContentType(contentType);
        fetchLessonContent(lessonId, contentType);
    };

    const handleAnswerSelect = (questionId, isCorrect) => {
        setSelectedAnswers((prev) => ({ ...prev, [questionId]: isCorrect }));
        setFeedback((prev) => ({ ...prev, [questionId]: isCorrect ? "Correct!" : "Incorrect. Try again." }));
    };


    const renderQuestions = (lessoncontent) => (
        lessoncontent.questions.map((question, index) => (
            <Question
                key={question.questionId}
                questionData={question}
                images={lessoncontent.personaContent.images[index] ? [lessoncontent.personaContent.images[index]] : []} 
                onAnswerSelect={handleAnswerSelect}
            />
        ))
    );

    const renderQuizQuestions = (lessoncontent) => (
        lessoncontent.questions.map((question, index) => (
            <Quiz
                key={question.questionId}
                quizData={question}
                images={lessoncontent.personaContent.images[index] ? [lessoncontent.personaContent.images[index]] : []} 
                onAnswerSelect={handleAnswerSelect}
            />
        ))
    );
    

    if (loading) {
        return <p>Loading...</p>;
    }

    return (
    <div className="material-page">
        <MaterialNavbar student={student} otherStudents={otherStudents} />
        <div className="material-content">
            <div className="table-of-contents">
                <h2>Table of Contents</h2>
                {lessons.map((lesson, index) => (
                    <div key={lesson.lessonId}>
                        <button className="accordion-button" onClick={() => handleToggle(index, lesson.lessonId)}>
                            {lesson.title}
                            <span className="accordion-arrow">
                                {expandedLessonIndex === index ? ' ▼' : ' ►'}
                            </span>
                        </button>
                        {expandedLessonIndex === index && (
                            <div className="accordion-content">
                                <div className="module-options">
                                    <button onClick={() => handleContentTypeSelect(lesson.lessonId, 'explanation')}>Explanation</button>
                                    <button onClick={() => handleContentTypeSelect(lesson.lessonId, 'exercise')}>Exercise</button>
                                    <button onClick={() => handleContentTypeSelect(lesson.lessonId, 'quiz')}>Quiz</button>
                                </div>
                            </div>
                        )}
                    </div>
                ))}
            </div>

            <div className="lesson-container">
                {lessonContent ? (
                    <div className="lesson-content">
                        <h2>{selectedContentType.charAt(0).toUpperCase() + selectedContentType.slice(1)}</h2>
                        {selectedContentType === 'explanation' ? (
                            Array.isArray(lessonContent.text) ? (
                                lessonContent.text.map((item, index) => (
                                    <div key={index} className="section">
                                        <h4 className="section-title">{item.section}</h4>
                                        {Array.isArray(item.content) ? (
                                            <div className="content">
                                                {item.content.map((subItem, subIndex) => (
                                                    <div key={subIndex}>
                                                        <strong>{subItem.type}</strong>: {subItem.description}
                                                    </div>
                                                ))}
                                            </div>
                                        ) : (
                                            <p>{typeof item.content === 'string' ? item.content : JSON.stringify(item.content)}</p>
                                        )}
                                        {lessonContent.images && lessonContent.images[index - 1] && index >= 1 && (
                                            <img 
                                                src={lessonContent.images[index - 1]} 
                                                alt={`Visual for ${item.section}`} 
                                                style={{ width: '100%', height: 'auto' }} 
                                            />
                                        )}
                                        {/* Render videos if available */}
                                        {index === 1 && lessonContent.videos && lessonContent.videos.length > 0 && (
                                            <div className="video-container">
                                                <iframe
                                                    width="100%"
                                                    height="315"
                                                    src={lessonContent.videos[0].replace("watch?v=", "embed/")} 
                                                    title={`Video for ${item.section}`}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    style={{ marginBottom: '20px' }} 
                                                ></iframe>
                                            </div>
                                        )}
                                        {index === 3 && lessonContent.videos && lessonContent.videos.length > 1 && (
                                            <div className="video-container">
                                                <iframe
                                                    width="100%"
                                                    height="315"
                                                    src={lessonContent.videos[1].replace("watch?v=", "embed/")} 
                                                    title={`Video for ${item.section}`}
                                                    frameBorder="0"
                                                    allowFullScreen
                                                    style={{ marginBottom: '20px' }} 
                                                ></iframe>
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>Loading content available.</p>
                            )
                        ) : selectedContentType === 'exercise' && Array.isArray(lessonContent.questions) ? (
                            renderQuestions(lessonContent)
                        ) : selectedContentType === 'quiz' && Array.isArray(lessonContent.questions) ? (
                            renderQuizQuestions(lessonContent)
                        ) : (
                            <p>Loading content...</p>
                        )}
                    </div>
                ) : (
                    <p>Loading lesson content...</p>
                )}
            </div>
        </div>
    </div>
);

};

export default MaterialPage;
